/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-zip-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.5 9.438V8.5h-1v.938a1 1 0 01-.03.243l-.4 1.598.93.62.93-.62-.4-1.598a1 1 0 01-.03-.243"/><path pid="1" d="M4 0h8a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2V2a2 2 0 012-2m2.5 8.5v.938l-.4 1.599a1 1 0 00.416 1.074l.93.62a1 1 0 001.109 0l.93-.62a1 1 0 00.415-1.074l-.4-1.599V8.5a1 1 0 00-1-1h-1a1 1 0 00-1 1m1-5.5h-1v1h1v1h-1v1h1v1H9V6H8V5h1V4H8V3h1V2H8V1H6.5v1h1z"/>',
    },
});
